function $(id) { return document.getElementById(id); };
const media = $('audio');
const playerItems = document.querySelectorAll(".player-list li .item-title");
const playerTimers = document.querySelectorAll(".player-list li span");

let ui = {
  play: 'playAudio',
  audio: 'audio',
  percentage: 'percentage',
  seekObj: 'seekObj',
  currentTime: 'currentTime'
};

function togglePlay() {
  if (media.paused === false) {
    media.pause();
    $(ui.play).classList.remove('pause');
  } else {
    media.play();
    $(ui.play).classList.add('pause');
  }
}

function calculatePercentPlayed() {
  let percentage = (media.currentTime / media.duration).toFixed(4) * 100;
  $(ui.percentage).style.left = `${percentage}%`;
}

function calculateCurrentValue(currentTime) {
  const currentMinute = parseInt(currentTime / 60) % 60;
  const currentSecondsLong = currentTime % 60;
  const currentSeconds = currentSecondsLong.toFixed();
  const currentTimeFormatted = `${currentMinute < 10 ? `0${currentMinute}` : currentMinute}:${
    currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds
  }`;

  return currentTimeFormatted;
}

function initProgressBar() {
  const currentTime = calculateCurrentValue(media.currentTime);
  $(ui.currentTime).innerHTML = currentTime;
  $(ui.seekObj).addEventListener('click', seek);

  media.onended = () => {
    $(ui.play).classList.remove('pause');
    $(ui.percentage).style.left = 0;
    $(ui.currentTime).innerHTML = '00:00';
  };

  function seek(e) {
    const percent = e.offsetX / this.offsetWidth;
    media.currentTime = percent * media.duration;
  }

  calculatePercentPlayed();
}

function getSrc(link){
  const src = link.parentElement.dataset.src;
  media.src = src;

  cT = link.nextElementSibling;

  playerTimers.forEach((timer) =>{
    timer.setAttribute("id","" );
    timer.innerHTML = timer.dataset.default;
  });
  cT.setAttribute("id","currentTime" );


  togglePlay();

}


$(ui.play)?.addEventListener('click', togglePlay)
$(ui.audio)?.addEventListener('timeupdate', initProgressBar);

playerItems.forEach((item) => {
  item.addEventListener("click", () =>{
    getSrc(item);
  });
});
