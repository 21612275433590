window.addEventListener("DOMContentLoaded", (event) => {

  const menuToggle = document.querySelector(".menu-toggle");
  const menu = document.querySelector(".header .menu");

  menuToggle.addEventListener("click", () => {
    menuToggle.classList.toggle("is-active");
    menu.classList.toggle("show");
  });

});
